import './styles.scss';
import './resume.pdf';
import './favicon-32x32.png';
import axios from 'axios';

const encEmail = "am9ic0Bnb3RvdmFjLmNh";
const contact = document.getElementById("contact");
contact.setAttribute("href", "mailto:".concat(atob(encEmail)));

axios.get(`${BASE_URL ?? ''}/helloworld`)
  .then(r => r.data)
  .then(console.log);
